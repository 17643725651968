









import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DisplayBoolean extends Vue {
  @Prop({ required: true, type: Boolean }) value!: boolean;
  @Prop({ required: false, type: String, default: "" }) mode!: "active" | "yes";

  get color(): string {
    return this.value ? "#52c41a" : "#f5222d";
  }

  get icon(): string {
    return this.value ? "check" : "close";
  }

  get label(): string {
    let value = "";
    if (this.mode === "active") {
      value = this.value
        ? this.$t("lbl_active").toString()
        : this.$t("lbl_inactive").toString();
    } else if (this.mode === "yes") {
      value = this.value
        ? this.$t("lbl_yes").toString()
        : this.$t("lbl_no").toString();
    }

    return value;
  }
}
