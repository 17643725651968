var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-tag",
    { attrs: { color: _vm.color, closeable: false } },
    [
      _c("a-icon", {
        staticStyle: { "font-size": "14px" },
        attrs: { type: _vm.icon }
      }),
      _vm._t("default", function() {
        return [_vm._v(" " + _vm._s(_vm.label || null) + " ")]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }